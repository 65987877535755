<template>

  <div class="scheme_item" v-if="scheme">
    
    <h2 class="name_divider">{{scheme.name}}</h2>
    
    <div class="schemes_table">

      <div class="thead">
        <div class="item item_name">{{ $t('product_view_subtitle_feeding_weeks') }}</div>
        <div class="item" v-for="week in 12">
          {{ week }}
        </div>
      </div>

      <div class="tbody" v-for="pro in scheme.items_product">
        <div class="item item_name">
          <NuxtLink class="name_seed" :to="pro.link">
            <img class="avatar_nut" :src="pro.avatar_small"/>
          </NuxtLink>
          <NuxtLink class="name_seed" :to="pro.link">
            {{ pro.name }}
          </NuxtLink>
        </div>

        <div :class="'item ' + (pro['w'+week] ? '' : 'disable')" v-for="week in 12">
          {{ pro['w'+week] || '0' }}
          <div class="unit">{{ $constants.nutStateLabel[pro.state] ? $t($constants.nutStateLabel[pro.state]) : '' }}</div>
        </div>

      </div>
    </div>
    
  </div>

</template>

<script>
 
export default {   
  components: {
  },
  props: [
    'data',
    'scheme'
  ],
  data () {
    return {            
    }
  }, 
  created() {    
    // this.query = this.$route.query.q;    
  },
  methods: {
     
  }
}
</script>

<style scoped>
 
  
/* schemes table */

.scheme_item{
  width: 100%;
}

.scheme_item .name_divider{
  border-top: 1px #efefef solid;
  padding-top: 30px;
}


.schemes_table{
  margin-top: 30px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: column;
     align-items: flex-start;
     overflow-x: auto;
}
.schemes_table .thead {
  display: flex;
  justify-content: center;
}

.schemes_table .thead .item {

    width: 55px;
  text-align: center;
  padding: 10px;
  border-right: 2px white solid;
  border-left: 2px white solid;
  border-radius: 3px;
  display: flex;
  align-items: center;
  align-content: center;
  flex-direction: column;
  background-color: #d2e8c5;
    height: 50px;
    font-weight: bold;
    font-size: 1.2rem;
    padding-top: 14px;
}


.schemes_table .tbody {
  display: flex;
  justify-content: center;
}


.schemes_table .tbody .item .unit{
  font-size: 0.75rem;
}

.schemes_table .tbody .item .hint{
  font-size: 0.75rem;
}
.schemes_table .tbody .item {
  width: 55px;
      text-align: center;
      padding: 10px;
      background-color: #f9f9f9;
      border: 2px white solid;
      border-radius: 3px;
      display: flex;
      align-items: center;
      align-content: center;
      justify-content: center;
      flex-direction: column;
      height: 70px;
}
.schemes_table .tbody .item_name ,
.schemes_table .thead .item_name {
  width: 200px;
  flex-direction: row;
  justify-content: flex-start;
}
.schemes_table .tbody .item .avatar_nut{

      max-width: 50px;
    max-height: 50px;
      margin-right: 15px;
}
.schemes_table .tbody .item .link_avatar_nut{
  width: 50px;
}
.schemes_table .tbody .item.disable{
      opacity: 0.2;
}
.schemes_table .tbody .item .link_name_nut{
  text-align: left;
}



</style>
