<template>
  <div class="pbf grid_width">
 

    <div class="">
      <template v-for="pro in data">
        <BrandSchemeItem
          :scheme="pro"
          />
      </template>
    </div>
  

  </div>
</template>

<script>

  
export default { 
  components: {    
   
  },  
  props:{
    inurl:{
      type: String
    }, 
  },
  data () {
    return {          
      data: {},
      url: this.inurl, 
    }
  },
  // async fetch() {    
    //if(import.meta.server){
      // await this.loadResults();      
    //}      
  // }, 
  created() {    
    // this.query = this.$route.query.q;
    //if(!import.meta.server)
      this.loadResults(); 
    // this.tags = tagsBrandSeedlist;    
  },
  methods: {
    async loadResults(){



      var dt = await this.$api.getUrl(this.url, {        
                         
      }) 


      this.data = dt;
        
     }
  }
}
</script>

<style scoped>



</style>
